:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  // Used when no type is provided
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

/** Used to define container behavior: width, position: fixed etc... **/
body {
  .Toastify__toast-container {
    @apply w-[911px];
  }

  /** Used to define the position of the ToastContainer **/
  .Toastify__toast-container--top-left {
  }

  .Toastify__toast-container--top-center {
  }

  .Toastify__toast-container--top-right {
  }

  .Toastify__toast-container--bottom-left {
  }

  .Toastify__toast-container--bottom-center {
  }

  .Toastify__toast-container--bottom-right {
  }

  /** Classes for the displayed toast **/
  .Toastify__toast {
    font-family: "Inter";
    border-radius: 12px;
    box-shadow: 0px 0px 0px;
    // padding: 24px 20px ;
    padding: 16px;
    width: 911px;
  }

  .Toastify__toast--rtl {
  }

  .Toastify__toast-body {
    padding: 0px;
    font-size: 0.85rem;
    font-weight: 700;
  }

  /** Used to position the icon **/
  .Toastify__toast-icon {
    width: 24px;
    height: 24px;
    margin-inline-end: 16px;
    display: flex;
    align-self: flex-start;
  }

  .Toastify__toast-icon svg {
    width: 24px !important;
    height: 24px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /** handle the notificaiton color and the text color based on the theme **/
  .Toastify__toast-theme--dark {
    background-color: #323233;
  }

  .Toastify__toast-theme--light {
    background-color: transparent;
    color: #434343;
  }

  .Toastify__toast-theme--colored.Toastify__toast--default {
    background-color: red !important;
    color: green !important;
  }

  .Toastify__toast-theme--colored.Toastify__toast--info {
    background-color: red !important;
    color: green !important;
  }

  .Toastify__toast--info .Toastify__toast-icon {
    fill: #595959;
  }

  .Toastify__toast--info {
    background-color: #f5f5f5;
  }

  .Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: red !important;
    color: green !important;
  }

  .Toastify__toast--success .Toastify__toast-icon {
    fill: #389e0d;
  }

  .Toastify__toast--success {
    background-color: #f6ffed;
  }

  .Toastify__toast-theme--colored.Toastify__toast--warning {
  }

  .Toastify__toast--warning .Toastify__toast-icon {
    fill: #ffa940;
  }

  .Toastify__toast--warning {
    background-color: #fff7e6;
  }

  .Toastify__toast-theme--colored.Toastify__toast--error {
  }

  .Toastify__toast--error .Toastify__toast-icon {
    fill: #cf1322;
  }

  .Toastify__toast--error {
    background-color: #fff1f0;
  }

  .Toastify__progress-bar {
  }

  .Toastify__progress-bar--rtl {
  }

  .Toastify__progress-bar-theme--light {
  }

  .Toastify__progress-bar-theme--dark {
  }

  .Toastify__progress-bar--info {
  }

  .Toastify__progress-bar--success {
  }

  .Toastify__progress-bar--warning {
  }

  .Toastify__progress-bar--error {
  }

  /** colored notifications share the same progress bar color **/
  .Toastify__progress-bar-theme--colored.Toastify__progress-bar--info,
  .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success,
  .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning,
  .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  }

  /** Classes for the close button. Better use your own closeButton **/
  .Toastify__close-button {
  }

  .Toastify__close-button--default {
  }

  .Toastify__close-button:hover,
  .Toastify__close-button:focus {
  }

  @media only screen and (max-width: 920px) {
    .Toastify__toast {
      max-width: 700px;
    }
  }

  @media only screen and (max-width: 768px) {
    .Toastify__toast {
      width: auto;
      max-width: 600px;
    }
  }

  @media only screen and (max-width: 480px) {
    .Toastify__toast-container--bottom-center {
      transform: translateX(-50%);
      left: 50%;
      max-width: 90%;
      min-width: 70%;
    }
  }
}
