// // Fonts
// @import "./fonts";

// // Tailwind
// @import "./base";
// @import "tailwindcss/components";
// @import "tailwindcss/utilities";

@tailwind base;

@layer base {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 16px;
  }
  h5 {
    font-size: 13px;
  }
  h6 {
    font-size: 11px;
  }
}

@tailwind components;
@tailwind utilities;

button {
  font-weight: 300;
}

@import "./toast.scss";

/* scrollbar styles */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 50px;
}

::-webkit-scrollbar-track {
  background: white;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #bfbfbf;
}

.hidden-scrollbar::-webkit-scrollbar {
  width: 8px;
  display: none !important;
}

.thin-height-scrollbar::-webkit-scrollbar {
  height: 8px;
}

.ant-table-content::-webkit-scrollbar {
  height: 8px !important;
}

.ant-table-wrapper .ant-table {
  scrollbar-color: auto !important;
}

.extra-thin-height-scrollbar::-webkit-scrollbar {
  height: 2px;
}

.scroll-gray-background::-webkit-scrollbar-track {
  background: #f5f5f5 !important;
}

.scroll-gray-10-background::-webkit-scrollbar-track {
  background: #fafafa !important;
}

body {
  padding: 0;
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  //   Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-family: Inter;
  overflow-y: hidden;
  @apply text-gray-900 w-screen overflow-x-hidden; // ?
  scrollbar-width: thin !important;
}

html {
  height: -webkit-fill-available;
}
.webkit-fill-available {
  max-height: -webkit-fill-available;
}

.no-decoraton {
  text-decoration: none;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/Inter-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/Inter-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/Inter-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/Inter-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/Inter-ExtraBold.ttf");
  font-weight: 800;
}

// typography
.h1-reg-100 {
  @apply text-4xl leading-none text-cobalt-400;
}

.subheader-bold-100 {
  @apply text-lg leading-none text-cobalt-500 font-bold;
}

.label-reg {
  @apply text-xs leading-tight;
}

.privacy-content {
  h2 {
    @apply text-3xl font-bold mt-4 mb-2;
  }

  h3 {
    @apply text-xl font-bold mt-4 mb-2;
  }

  li {
    list-style-type: circle;
  }

  ul {
    @apply my-4;
  }
}

.tooltip-glyph-shadow {
  filter: drop-shadow(0px 3px 8px rgba(112, 120, 135, 0.24));
}

.wrap {
  position: relative;
}

.wrap:before {
  content: "";
  position: absolute;
  border-top: 1px solid #e6e8f3;
  width: 100%;
}

.wrap2:after {
  content: "";
  position: absolute;
  border-left: 1px solid #e6e8f3;
  height: 100%;
}

.similar-brands-table {
  table-layout: auto;
  border-collapse: collapse;
  thead {
    td {
      @apply p-4;
    }
  }

  th {
    @apply text-13 font-normal;
  }

  td,
  th {
    @apply p-0;
  }

  td {
    white-space: nowrap;
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.animate-spin-stick-vertical {
  animation: RotateWithVerticalStick 800ms 0ms
    cubic-bezier(0.502051282051282, 0, 0.5133333333333333, 0.9794871794871794)
    forwards infinite;
}

@keyframes RotateWithVerticalStick {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

input[type="checkbox"] {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.common-grid {
  @apply grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  @apply appearance-none;
}

// tooltip related styles
.tooltip-container {
  --tooltipBackground: #000;
  --tooltipColor: #fff;

  background-color: var(--tooltipBackground);
  color: var(--tooltipColor);
  transition: opacity 500ms;
  z-index: 9999;

  @apply rounded-md z-30 text-13 max-w-[300px] p-4;
}

.tooltip-container[data-popper-interactive="false"] {
  pointer-events: none;
}

.tooltip-arrow {
  height: 1rem;
  position: absolute;
  width: 1rem;
  pointer-events: none;
}

.tooltip-arrow::before {
  border-style: solid;
  content: "";
  display: block;
  height: 0;
  margin: auto;
  width: 0;
}

.tooltip-arrow::after {
  border-style: solid;
  content: "";
  display: block;
  height: 0;
  margin: auto;
  position: absolute;
  width: 0;
}

.tooltip-container[data-popper-placement*="bottom"] .tooltip-arrow {
  left: 0;
  margin-top: -0.4rem;
  top: 0;
}

.tooltip-container[data-popper-placement*="bottom"] .tooltip-arrow::before {
  border-color: transparent transparent var(--tooltipBorder) transparent;
  // border-width: 0 0.5rem 0.4rem 0.5rem;
  position: absolute;
  top: -1px;
}

.tooltip-container[data-popper-placement*="bottom"] .tooltip-arrow::after {
  border-color: transparent transparent var(--tooltipBackground) transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
}

.tooltip-container[data-popper-placement*="top"] .tooltip-arrow {
  bottom: 0;
  left: 0;
  margin-bottom: -1rem;
}

.tooltip-container[data-popper-placement*="top"] .tooltip-arrow::before {
  border-color: var(--tooltipBorder) transparent transparent transparent;
  // border-width: 0.4rem 0.5rem 0 0.5rem;
  position: absolute;
  top: 1px;
}

.tooltip-container[data-popper-placement*="top"] .tooltip-arrow::after {
  border-color: var(--tooltipBackground) transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
}

.tooltip-container[data-popper-placement*="right"] .tooltip-arrow {
  left: 0;
  margin-left: -0.7rem;
}

.tooltip-container[data-popper-placement*="right"] .tooltip-arrow::before {
  border-color: transparent var(--tooltipBorder) transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
}

.tooltip-container[data-popper-placement*="right"] .tooltip-arrow::after {
  border-color: transparent var(--tooltipBackground) transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
  left: 6px;
  top: 0;
}

.tooltip-container[data-popper-placement*="left"] .tooltip-arrow {
  margin-right: -0.7rem;
  right: 0;
}

.tooltip-container[data-popper-placement*="left"] .tooltip-arrow::before {
  border-color: transparent transparent transparent var(--tooltipBorder);
  border-width: 0.5rem 0 0.5rem 0.4em;
}

.tooltip-container[data-popper-placement*="left"] .tooltip-arrow::after {
  border-color: transparent transparent transparent var(--tooltipBackground);
  border-width: 0.5rem 0 0.5rem 0.4em;
  left: 3px;
  top: 0;
}
// finished tooltip related styles
.rainbow-box {
  // width: 80vw;
  // height: 200px;
  // border-radius: 5px;
  background: linear-gradient(
    90deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 154, 0, 1) 10%,
    rgba(208, 222, 33, 1) 20%,
    rgba(79, 220, 74, 1) 30%,
    rgba(63, 218, 216, 1) 40%,
    rgba(47, 201, 226, 1) 50%,
    rgba(28, 127, 238, 1) 60%,
    rgba(95, 21, 242, 1) 70%,
    rgba(186, 12, 248, 1) 80%,
    rgba(251, 7, 217, 1) 90%,
    rgba(255, 0, 0, 1) 100%
  );
}

.title {
  @apply text-2xl font-bold text-heading leading-tight;
}

.sub-title {
  @apply text-sm leading-normal font-normal text-body;
}

.ai_streaming_project:hover {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
}

.card-shadow:hover {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
}

.retail-price .apexcharts-tooltip {
  top: 10px !important;
}

.trend-breakdown-chart .apexcharts-tooltip {
  top: 10px !important;
}

.tooltip-top .apexcharts-tooltip {
  top: 10px !important;
}

.trend-breakdown-chart .svg_select_points_r {
  position: absolute;
  width: 12px;
  height: 22px;
  fill: #dfdfdf;
  stroke: none;
  rx: 0;
  transform: translateY(-7px) translateX(4px);
}

.trend-breakdown-chart .svg_select_points_l {
  width: 12px;
  height: 22px;
  fill: #dfdfdf;
  stroke: none;
  rx: 0;
  transform: translateY(-7px) translateX(-7px);
}

.trend-breakdown-chart text {
  font-weight: 300 !important;
}

.trend-breakdown-chart
  .apexcharts-grid-borders
  .apexcharts-gridline:first-of-type {
  display: none;
}

// Typography
@layer components {
  .title-100 {
    font-size: 40px;
    line-height: 125%;
    @apply text-gray-950 font-bold;
  }

  .headline-100 {
    font-size: 24px;
    line-height: 30px;
    @apply text-gray-950 font-bold;
  }
  .headline-200 {
    font-size: 18px;
    line-height: 22.5px;
    @apply text-gray-950 font-bold;
  }
  .headline-300 {
    font-size: 16px;
    line-height: 20px;
    @apply text-gray-950 font-bold;
  }
  .headline-400 {
    font-size: 14px;
    line-height: 17.5px;
    @apply text-gray-950 font-bold;
  }
  .headline-500 {
    font-size: 14px;
    line-height: 17.5px;
    @apply text-gray-950 font-semibold;
  }

  .body-100 {
    font-size: 14px;
    line-height: 17.5px;
    @apply text-gray-80;
  }
  .body-100-bold {
    font-size: 14px;
    line-height: 17.5px;
    @apply text-gray-80 font-semibold;
  }
  .body-100-subtle {
    font-size: 14px;
    line-height: 17.5px;
    @apply text-gray-60;
  }
  .body-100-disabled {
    font-size: 14px;
    line-height: 17.5px;
    @apply text-gray-40;
  }

  .hint-100 {
    font-size: 12px;
    line-height: 18px;
    @apply text-gray-70;
  }

  .error-100 {
    font-size: 12px;
    line-height: 18px;
    @apply text-red-50;
  }

  .link-100 {
    font-size: 14px;
    line-height: 21px;
    text-decoration: none;
    @apply text-indigo-70;
  }
  .link-100:hover {
    text-decoration: underline;
  }

  .tab-200 {
    font-size: 16px;
    line-height: 20px;
    @apply text-indigo-70 font-semibold;
  }

  .label-100 {
    font-size: 14px;
    line-height: 21px;
    @apply text-gray-80;
  }
  .optional-100 {
    font-size: 14px;
    line-height: 21px;
    @apply text-gray-40;
  }
}

// comment input
.comment-input__control {
  font-size: 14px;
  line-height: 17px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 6px;
}

.comment-input__input {
  padding: 6px;
  border-radius: 4px;
}

.comment-input__input:disabled {
  background-color: #e2dede !important;
}

.comment-input__suggestions {
  border-radius: 6px;
  margin-top: 30px !important;
}

// message input
.message-input__control {
  width: 100%;
  font-size: 14px;
  line-height: 17px;
}

.message-input__input {
  margin: 0 !important;
}

.message-input__input:focus-visible {
  outline: none;
}

.message-input__input:disabled {
  background-color: #e2dede !important;
}

// calendar
.rbc-event {
  padding: 0 !important;
  max-height: inherit !important;
  background-color: transparent !important;
}

// time-picker
.react-time-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 4px;
  border: none !important;
}

.markdown > h1 {
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.markdown > h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.markdown > h3 {
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.markdown > h4 {
  display: block;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.markdown > h5 {
  display: block;
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.markdown > p {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
}

.react-time-picker__wrapper {
  border-color: #d9d9d9 !important;
  border-radius: 6px;
  padding-left: 14px;
  padding-right: 16px;
}

.react-time-picker__inputGroup {
  height: 30px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

//orders table styles
.expandable-row .ant-table-cell {
  padding: 0 !important;
  background-color: white !important;
}

.expandable-row .ant-table-expanded-row-fixed {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ant-table-thead th {
  font-size: 12px;
  font-weight: 500 !important;
  line-height: 120% !important;
  color: #8c8c8c !important;
  background-color: white !important;
  border-color: #d9d9d9 !important;
  white-space: nowrap;
  max-width: 188px !important;
}

.ant-table-thead > tr > th::before {
  width: 0 !important;
  height: 0 !important;
}

.ant-table-tbody td {
  border-color: #d9d9d9 !important;
}

.ant-table-tbody tr {
  cursor: pointer;
}

.report-table tr:last-child td {
  border-bottom: none !important;
}

html {
  -webkit-print-color-adjust: exact;
}

.article-drawer-editor .react-page-row {
  flex-direction: column !important;
}

.article-drawer-editor .react-page-cell {
  width: 100% !important;
  height: auto !important;
}

.article-drawer-editor .react-page-cell-inner {
  width: 100% !important;
  height: auto !important;
}

.ant-switch {
  background: rgba(0, 0, 0, 0.25) !important;
}

.ant-switch.ant-switch-checked {
  background: #341bf9 !important;
}

.ant-switch-checked .ant-switch-loading-icon svg {
  fill: #341bf9;
}

.range-chart-bar div{
  height: 100%;
}