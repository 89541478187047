.linear-loading {
    width: 100%;
    position: relative;
    animation-duration: 3s;
    animation-name: linear-loading;
    animation-iteration-count: infinite;
    animation-delay: 0.5s;
    animation-timing-function: linear;
}

.linear-loading-bg {
    width: 100%;
    position: absolute;
    top: -4px;
    height: 10px;
    background: linear-gradient(270deg, rgba(154, 141, 252, 0.80) 0%, rgba(255, 255, 255, 0.00) 100%);
    filter: blur(6px);
}

.linear-loading-stick {
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, #341BF9 0%, #715FFB 33.33%, #AEA4FD 66.67%, #F9FFFF 100%);
}

@keyframes linear-loading {
    0%   {width:0%; left: 0%;top:0px;}
    60%  {width:100%;left: 0%; top:0px;}
    100% {width:100%; left: 100%; top:0px;}
}