.date-range-container {
    border: none;
}

.date-range-container-footer button {
    @apply border-none p-2 ring-0;
    background-color: white!important;
    color: #4B35F5!important;
} 
.date-range-container-footer button:hover {
    background-color: #FAFAFA!important;
}

.date-range-container .bg-blue-100 {
    @apply bg-gray-30;
}

.date-range-container .bg-blue-500 {
    @apply bg-gray-950 text-white;
}

.date-range-container ul {
    @apply gap-2 px-3 pb-2;
    padding-top: 53px;
}

.date-range-container ul li {
    @apply px-3 bg-gray-20 rounded-md w-fit text-typography-body-normal;
    padding-top: 7px;
    padding-bottom: 7px;
}

.date-range-container ul li:hover {
    @apply bg-gray-30;
}
.date-range-container ul li:active {
    @apply bg-gray-40;
}

@media only screen and (max-width: 1024px) {
    .date-range-container ul  {
        padding-top: 16px;
    }
}

.date-range-container :last-child :last-child div {
    border: none;
}